<template>
  <div class="home">
    <div>
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B2/images/BW2--B2_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B2/images/BW2--B2_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B2/images/BW2--B2_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B2/images/BW2--B2_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B2/images/BW2--B2_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B2/images/BW2--B2_06.png" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B2/images/BW2--B2_07.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B2/images/BW2--B2_08.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B2/images/BW2--B2_09.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B2/images/BW2--B2_10.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "低功耗wifi-BW2--B2",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>

</style>